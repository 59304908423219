import { HiOutlineUserCircle } from 'react-icons/hi';
import styled from 'styled-components';

import { BaseDropdownItem, BaseLoading } from '@gbs-monorepo-packages/common';
import { colors, radii } from '@gbs-monorepo-packages/styles';

export const Center = styled.div`
  text-align: center;
`;

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Header = styled.header`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const TitlePage = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 337px) {
    font-size: 1.5rem;
  }
  @media (max-width: 279px) {
    font-size: 1.2rem;
  }
`;

export const MainContent = styled.div`
  background-color: ${colors.white300};
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
`;

export const DropdownItem = styled(BaseDropdownItem)`
  font-size: 1rem;
  font-weight: 400;

  all: 'unset';
  line-height: 1.125rem;
  color: ${colors.labelText};
  height: auto;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  padding-left: 25;
  user-select: none;
  border-radius: ${radii.sm};

  &[data-highlighted],
  &:hover {
    background-color: ${colors.backgroundHover};
    outline: none;
  }
`;

export const UserIcon = styled(HiOutlineUserCircle)`
  height: 2rem;
  width: 2rem;
`;

export const ItemGridCenter = styled.div`
  color: ${colors.textLight};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0.5rem 1rem;
`;

export const DropdownButtonContainer = styled.div`
  align-items: center;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;
  width: 2.75rem;
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;
