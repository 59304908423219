import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  ABORT_REQUEST_REASON,
  DefaultDescription,
  FormBase,
  FormModal,
  INIT_PAGE,
  type IPaginationMetaProps,
  type IRequestAbortController,
  type ISelectFilterValue,
  LIMIT_PAGE,
  Logger,
  Roles,
  SelectData,
  UploadImageModal,
  assertAbortReason,
  generateAbortRequest,
  isAbortError,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { COMPANY_ID } from '../../../../constants/Env';
import { ManagerTypes } from '../../../../constants/ManagerTypes';
import { rolesWithLevel } from '../../../../constants/Roles';
import {
  type UsersCreateSchema,
  userClientCreateSchema,
} from '../../../../formSchemas/userSchema';
import {
  type ICompanyDTO,
  getCompanies as getClients,
} from '../../../../services/companies';
import {
  type IRole,
  translateUserRoleInRole,
} from '../../../../utils/translateUserRoleInRole';
import { SelectContainer, SelectFilterData } from './styles';

const NotFoundIndex = -1 as const;
const NoExtraIndex = undefined;
type IExtraIndex = typeof NoExtraIndex | typeof NotFoundIndex;

interface IAddTeammatesProps {
  onAccept: (data: UsersCreateSchema) => void;
  onDecline: () => void;
  open: boolean;
  loading: boolean;
  success?: boolean;
}

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

export const ModalAddUsers = ({
  onAccept,
  onDecline,
  open,
  loading = false,
  success = false,
}: IAddTeammatesProps): JSX.Element => {
  const { addToast } = useToast();

  const isSelectOpen = useRef(new Set());
  const { user } = useAuth();
  const selectData = useRef(null);
  const selectDataManagerType = useRef(null);
  const [imageFile, setImageFile] = useState<File | null>(null);

  const [client, setClient] = useState<ISelectFilterValue<ICompanyDTO> | null>(
    null
  );
  const [clients, setClients] = useState<ICompanyDTO[]>([]);
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const forcedSelectedClientIndex = useRef<IExtraIndex>();
  const lastRequestAbort = useRef<IRequestAbortController | null>(null);
  const searchClient = useRef('');

  const createUsers = useForm<UsersCreateSchema>({
    resolver: zodResolver(userClientCreateSchema),
    defaultValues: {
      roles: Roles.VIEWER,
    },
  });

  const [loadingClients, setLoadingClients] = useState(true);

  const {
    reset,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = createUsers;

  const values = watch();

  const handleRoleInviteSelectChange = (value: string) => {
    setValue('roles', value);
    setValue('managerType', '');
  };

  const handleManagerTypeInviteSelectChange = (value: string) => {
    setValue('managerType', value);
  };

  const searchClients = useCallback(
    async (newFilter = false) => {
      let pageAux = 0;
      if (newFilter) {
        lastRequestAbort.current?.abort(ABORT_REQUEST_REASON);
      } else {
        if (lastRequestAbort.current?.avoidConcurrency ?? false) {
          Logger.info(
            'Skip new page request, because there is a new search request'
          );
          return;
        }
        pageAux = paginationMeta?.page ?? 0;
      }

      const newRequestAbort = generateAbortRequest(newFilter);
      lastRequestAbort.current = newRequestAbort;

      try {
        const { data: newPageData, meta } = await getClients(
          {
            page: pageAux + 1,
            limit: LIMIT_PAGE,
            filter: searchClient.current ?? '',
          },
          {
            signal: newRequestAbort.signal,
          }
        );
        if (newFilter) {
          let addedExtraTemplate: IExtraIndex = NoExtraIndex;
          if (client?.id !== undefined) {
            const indexTemplate = newPageData.findIndex(
              ({ id }) => id === client.id
            );

            let newIndex = indexTemplate;
            if (indexTemplate === NotFoundIndex) {
              newPageData.push(client);
              addedExtraTemplate = NotFoundIndex;
              newIndex = newPageData.length - 1;
            }

            setClient(
              produce((draft) => {
                if (draft) draft.index = newIndex;
              })
            );
          }

          forcedSelectedClientIndex.current = addedExtraTemplate;
          setClients(newPageData);
        } else {
          const extraIndex = forcedSelectedClientIndex.current;
          if (extraIndex !== NoExtraIndex && client?.id !== undefined) {
            const index = newPageData.findIndex(({ id }) => id === client.id);

            let offsetSelection = newPageData.length;
            let deleteExtra = 0;
            if (index !== NotFoundIndex) {
              deleteExtra = 1;
              forcedSelectedClientIndex.current = NoExtraIndex;
              offsetSelection = index;
            }
            setClients(
              produce((draft) => {
                draft.splice(extraIndex, deleteExtra, ...newPageData);
              })
            );
            setClient(
              produce((draft) => {
                if (draft) {
                  draft.index = offsetSelection + (draft.index ?? 0);
                }
              })
            );
          } else {
            setClients(
              produce((draft) => {
                draft.push(...newPageData);
              })
            );
          }
        }
        setPaginationMeta(meta);
      } catch (error) {
        if (
          isAbortError(error) &&
          assertAbortReason(newRequestAbort, ABORT_REQUEST_REASON)
        ) {
          Logger.info(
            'Request was aborted, because there is a new search request'
          );
          return;
        }
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting client',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'search-client-error-toast',
          duration: 3000,
        });
      } finally {
        if (lastRequestAbort.current === newRequestAbort) {
          lastRequestAbort.current = null;
        }
      }
    },
    [addToast, client, paginationMeta?.page]
  );

  const handleSearch = useCallback(
    async (newSearch: string) => {
      searchClient.current = newSearch;
      await searchClients(true);
    },
    [searchClients]
  );

  const handleClientSelectChange = useCallback(
    (newValue: ISelectFilterValue<ICompanyDTO>) => {
      setClient(newValue);
      setValue('clientId', newValue.id);
    },
    [setValue]
  );

  useEffect(() => {
    if (success) {
      resetForm();
    }
  }, [success]);

  const resetForm = useCallback(() => {
    reset();
    setClient(null);
  }, [reset]);

  const handleDeclineAddUsers = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [onDecline, resetForm]);

  useEffect(() => {
    if (!loading) {
      resetForm();
    }
  }, [loading]);

  useEffect(() => {
    let mount = true;

    const fetchClients = async () => {
      setLoadingClients(true);
      try {
        const { data, meta } = await getClients({
          page: INIT_PAGE,
          limit: LIMIT_PAGE,
          filter: '',
        });

        if (mount) {
          setClient(null);
          forcedSelectedClientIndex.current = NoExtraIndex;
          setClients(data);
          setPaginationMeta(meta);
        }
      } catch (error) {
        Logger.debug('error: ', error);
        addToast({
          title: 'Error on getting client',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'fetch-client-error-toast',
          duration: 3000,
        });
      } finally {
        mount && setLoadingClients(false);
      }
    };

    if (open) {
      void fetchClients();
    }

    return () => {
      mount = false;
    };
  }, [addToast, open]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const onSubmit: SubmitHandler<UsersCreateSchema> = (
    data: UsersCreateSchema
  ) => {
    if (!isSelectOpen.current.size) {
      resetForm();
      const userAux: UsersCreateSchema = {
        ...data,
      };

      if (imageFile) {
        userAux.profile = imageFile;
      }

      onAccept?.(userAux);
    }
  };

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  const rolesAux = useMemo(() => {
    if (currentRole !== null) {
      return rolesWithLevel.filter(
        ({ level }) => level <= (currentRole?.level ?? 0)
      );
    } else {
      return [];
    }
  }, [currentRole]);

  const isLoading = loading || loadingClients;

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...createUsers}>
      <FormModal
        acceptText="Add User"
        declineText="Cancel"
        open={open}
        dataCy="add-userForm"
        mainText="New User"
        onOpenChange={handleDeclineAddUsers}
        onDecline={handleDeclineAddUsers}
        onAccept={handleSubmit(onSubmit)}
        loading={isLoading}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <UploadImageModal
            label="Profile Image"
            onSelectImage={(file) => {
              setImageFile(file);
            }}
            onRemoveImagePreview={() => {
              setImageFile(null);
            }}
          />
          <FormBase.InputContent
            filled={!!values.clientId}
            inputRef="select-client"
            label="Select Client"
            errorMessage={errors.clientId?.message}
            dataCy="selectClient"
          >
            <SelectContainer>
              <SelectFilterData
                attribute="name"
                data={clients}
                dataCy="button-select-data-client"
                disabled={isLoading}
                hasMore={clients.length < (paginationMeta?.total ?? 0)}
                name="select-company"
                next={searchClients}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'company');
                }}
                onSearch={handleSearch}
                onValueChange={handleClientSelectChange}
                pageSize={LIMIT_PAGE}
                placeholder=""
                value={client}
              />
            </SelectContainer>
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.firstName}
            inputRef="firstNameEdit"
            label="First Name"
            errorMessage={errors.firstName?.message}
            dataCy="label-First Name"
          >
            <FormBase.InputText
              dataCy="firstNameEdit-input"
              id="firstNameEdit"
              name="firstName"
              type="text"
              autoComplete="off"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.lastName}
            inputRef="lastNameEdit"
            label="Last Name"
            errorMessage={errors.lastName?.message}
            dataCy="label-Last Name"
          >
            <FormBase.InputText
              dataCy="lastNameEdit-input"
              id="lastNameEdit"
              name="lastName"
              type="text"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.email}
            inputRef="emailInvite"
            label="Email"
            errorMessage={errors.email?.message}
            dataCy="label-Email"
          >
            <FormBase.InputText
              dataCy="emailInvite-input"
              id="emailInvite"
              name="email"
              type="text"
              maxLength={40}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.phone}
            inputRef="phoneInvite"
            label="Phone"
            errorMessage={errors.phone?.message}
            dataCy="label-Phone"
          >
            <FormBase.InputText
              dataCy="phoneInvite-input"
              id="phoneInvite"
              name="phone"
              type="text"
              maxLength={15}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.roles.length}
            inputRef="select-role"
            label="Permission"
            errorMessage={errors.roles?.message}
            dataCy="label-Permission"
            value={values.roles}
            isSelectData
          >
            <SelectData
              data={
                values.clientId === Number(COMPANY_ID)
                  ? rolesAux
                  : rolesAux.slice(-3)
              }
              value={values.roles ?? undefined}
              dataCy="button-select-role-field"
              onValueChange={handleRoleInviteSelectChange}
              zIndex={2}
              ref={selectData}
              onOpenChange={(isOpen) => {
                handleOpenChange(isOpen, 'state');
              }}
              placeholder="Permission"
            />
          </FormBase.InputContent>

          {values.roles.includes(Roles.MANAGER) && (
            <FormBase.InputContent
              filled={!!values.managerType}
              inputRef="select-managerType"
              label="Manager Type"
              errorMessage={errors.managerType?.message}
              dataCy="selectManagerType"
              value={values.managerType ? 'managerType' : undefined}
              isSelectData
            >
              <SelectData
                data={ManagerTypes}
                value={
                  values.managerType !== '' ? values.managerType : undefined
                }
                dataCy="select-managerType-field"
                onValueChange={handleManagerTypeInviteSelectChange}
                zIndex={2}
                ref={selectDataManagerType}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'managerType');
                }}
                placeholder="Manager Type"
              />
            </FormBase.InputContent>
          )}
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
