import { produce } from 'immer';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Can, type IUserDTO, useAuth } from '@gbs-monorepo-packages/auth';
import {
  BaseDropdown,
  BaseModal,
  Button,
  SearchBar,
  SelectData,
  ServerError,
  Table,
  TableCell,
  TableImageCell,
  useToast,
} from '@gbs-monorepo-packages/common';

import LoadingSpinnerAnimated from '../../assets/spinner.svg';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { COMPANY_ID } from '../../constants/Env';
import { Roles, rolesWithLevel } from '../../constants/Roles';
import { REDIRECT } from '../../constants/RoutePaths';
import {
  type UsersCreateSchema,
  type UsersUpdateSchema,
} from '../../formSchemas/userSchema';
import {
  type IApiThrowsError,
  type IPaginationMetaProps,
} from '../../services/api';
import {
  type IPaginationUserDTO,
  deleteMember,
  getUsersByCompany,
  inviteUser,
  updateMember,
} from '../../services/users';
import { getRouteFrom } from '../../utils/getRoutes';
import Logger from '../../utils/logger';
import {
  type IRole,
  translateUserRoleInRole,
} from '../../utils/translateUserRoleInRole';
import { ModalAddUsers } from './components/ModalAddUsers';
import { ModalUpdateUsers } from './components/ModalUpdateUsers';
import {
  ButtonContainer,
  Center,
  DropdownButtonContainer,
  DropdownItem,
  Header,
  ItemGridCenter,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  TitlePage,
  UserIcon,
} from './styles';

export interface ISelectClientsProps {
  onValueChange?: (value: string) => void;
}

interface IInviteError {
  firstNameInvite?: string;
  lastNameInvite?: string;
  emailInvite?: string;
  other?: string;
  managerTypeInvite?: string;
  phoneInvite?: string;
}

interface IUserWithCurrentRoles extends IUserDTO {
  currentRole?: IRole | null;
}

const AlmostRoles = [Roles.ADMIN, Roles.MANAGER, Roles.STAFF, Roles.CREW];

const getCurrentRole = (userRoles: string[]): IRole | null => {
  let currentRole = null;
  for (const value of userRoles.values()) {
    const userRoleAux = translateUserRoleInRole[value] ?? null;
    // const userRoleAux = roles.find(({ key }) => key === value);
    // userRoleAux can be null
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!userRoleAux) {
      continue;
    }

    if (!currentRole) {
      currentRole = userRoleAux;
    } else if (currentRole.level < userRoleAux.level) {
      currentRole = userRoleAux;
    }
  }

  return currentRole;
};

const headerColumns = [
  {
    id: 'user-data',
    name: 'User Name',
    textAlign: 'left',
  },
  {
    id: 'manager-type',
    name: 'Manager Type',
    textAlign: 'center',
  },
  {
    id: 'permission',
    name: 'Permission',
    textAlign: 'left',
  },
  {
    id: 'options',
    name: '',
    textAlign: 'center',
  },
];

export const MembersCompany = (): JSX.Element => {
  const navigate = useNavigate();
  const { companyId = '' } = useParams(); // need to save this value to CLIENTS route

  const { user } = useAuth();
  const [loadingMembers, setLoadingMembers] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [successRequest, setSuccessRequest] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);
  const [dialogEditOpen, setDialogEditOpen] = useState(false);

  const [users, setUsers] = useState<IUserWithCurrentRoles[]>([]);
  const [userToDelete, setUserToDelete] =
    useState<IUserWithCurrentRoles | null>(null);

  const [error, setError] = useState<IInviteError>({});

  const [idMemberToEdit, setIdMemberToEdit] = useState<number>(-1);
  const [firstNameEdit, setFirstNameEdit] = useState('');
  const [lastNameEdit, setLastNameEdit] = useState('');
  const [emailEdit, setEmailEdit] = useState('');
  const [managerTypeEdit, setManagerTypeEdit] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [roleEdit, setRoleEdit] = useState('');
  const [profileImage, setProfileImage] = useState('');

  const { addToast } = useToast();

  const isModalDeleteOpen = !!userToDelete;

  const [search, setSearch] = useState('');
  const [paginationMeta, setPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const lastSearch = useRef(search);

  const currentRole = useMemo(() => {
    const userRoles = user?.roles ?? [];
    if (!userRoles.length) {
      return null;
    }

    return getCurrentRole(userRoles);
  }, [user]);

  useEffect(() => {
    if (error.other) {
      addToast({
        title: 'Something went wrong',
        description: error.other,
        styleType: 'error',
        dataCy: 'error-toast',
      });
    }
  }, [addToast, error.other]);

  useEffect(() => {
    if (successRequest) {
      setTimeout(() => {
        setSuccessRequest(false);
      }, 2000);
    }
  }, [successRequest]);

  const rolesAux = useMemo(() => {
    if (currentRole !== null) {
      let roleAuxToFilter = rolesWithLevel;
      if (Number(companyId) !== Number(COMPANY_ID)) {
        roleAuxToFilter = rolesWithLevel.slice(-3);
      } else {
        roleAuxToFilter = rolesWithLevel.slice(0, 5);
      }

      return roleAuxToFilter.filter(
        ({ level }) => level <= (currentRole?.level ?? 0)
      );
    } else {
      return [];
    }
  }, [currentRole, companyId]);

  const handleDeclineDelete = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const handleAcceptDelete = useCallback(() => {
    if (userToDelete && isModalDeleteOpen) {
      setLoadingDelete(true);
      void deleteMember(userToDelete.id)
        .then(() => {
          getUsers(companyId, 1, 15);
        })
        .catch(() => {
          setLoadingDelete(false);
          setUserToDelete(null);
          setTimeout(() => {
            addToast({
              title: 'Something went wrong',
              description: 'An error occurred while deleting the user',
              styleType: 'error',
              dataCy: 'error-toast',
            });
          }, 1000);
        });
    }
  }, [userToDelete, isModalDeleteOpen, users]);

  const handleRoleSelectChange = (
    value: string,
    email: string,
    firstName: string,
    lastName: string,
    managerType?: string | null,
    phone?: string
  ) => {
    setDialogLoadingOpen(true);

    void updateMember({
      firstName,
      lastName,
      email,
      roles: Array(value),
      managerType,
      phone,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        setDialogLoadingOpen(false);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })

      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          if (error.response && error.response.status >= 500) {
            setError({
              other: ServerError,
            });
          } else {
            setError({
              other: error.response?.data.error.message,
            });
          }
        }, 1000);
      });
  };

  const handleProfileChange = (newProfileSrc: string, userId: number) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            accountFile: user.accountFile
              ? { ...user.accountFile, path: '' }
              : user.accountFile,
          };
        }
        return user;
      })
    );
    setProfileImage(newProfileSrc);
  };

  const inviteMemberSubmit = (data: UsersCreateSchema) => {
    setLoadingInvite(true);
    void inviteUser({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      roles: [data.roles],
      clientId: Number(companyId),
      managerType: data.managerType,
      phone: data.phone,
      profile: data.profile,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        setDialogOpen(false);
        setSuccessRequest(true);
      })
      .catch((error: IApiThrowsError) => {
        setTimeout(() => {
          let errorMessage = '';
          if (error.response && error.response.status >= 500) {
            errorMessage =
              'An error occurred. Please try again or contact Benefit Education support.';
          } else {
            errorMessage = error.response?.data.error.message ?? '';
          }
          addToast({
            title: 'Something went wrong',
            description: errorMessage,
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  const getUsers = (id: string, page: number, limit: number) => {
    setLoadingMembers(true);
    setLoadingEdit(true);
    getUsersByCompany(id, page, limit, search)
      .then((response: IPaginationUserDTO) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].currentRole = getCurrentRole(response.data[i].roles);
        }

        setUsers(response.data);
        setPaginationMeta(response.meta);

        if (dialogOpen) {
          addToast({
            title: 'Success',
            description: 'User has been added!',
            styleType: 'success',
            dataCy: 'success-toast',
          });
        }

        if (isModalDeleteOpen) {
          setLoadingDelete(false);
          setUserToDelete(null);
          addToast({
            title: 'Success',
            description: 'User deleted!',
            styleType: 'success',
            dataCy: 'success-toast',
          });
        }
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
      })
      .finally(() => {
        setLoadingMembers(false);
        setLoadingEdit(false);
      });
  };

  const handlerEditSubmit = (data: UsersUpdateSchema) => {
    setLoadingEdit(true);
    void updateMember({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      roles: [],
      managerType: data.managerType,
      phone: data.phone,
      profile: data.profile,
    })
      .then(() => {
        getUsers(companyId, 1, 15);
        setDialogEditOpen(false);
        setLoadingEdit(true);
        addToast({
          title: 'Success',
          description: 'User updated!',
          styleType: 'success',
          dataCy: 'success-toast',
        });
      })
      .catch((error: IApiThrowsError) => {
        Logger.debug('error: ', error);
        setTimeout(() => {
          addToast({
            title: 'Something went wrong',
            description: 'An error occurred while updating the user',
            styleType: 'error',
            dataCy: 'error-toast',
          });
        }, 2000);
      })
      .finally(() => {
        setLoadingEdit(false);
      });
  };

  const handleOpenModalEdit = (data: IUserWithCurrentRoles) => {
    setIdMemberToEdit(data.id);
    setEmailEdit(data.email);
    setFirstNameEdit(data.firstName);
    setLastNameEdit(data.lastName);
    setManagerTypeEdit(data.managerType ?? '');
    setPhoneEdit(data.phone ?? '');
    setRoleEdit(data.currentRole?.key ?? '');
    setProfileImage(data.accountFile?.path ?? '');
    setDialogEditOpen(true);
  };

  const handleOpenModalDelete = (data: IUserWithCurrentRoles) => {
    setUserToDelete(data);
  };

  const searchUsers = (searchByButton = false) => {
    if (!loadingMembers || searchByButton) {
      setLoadingMembers(true);
      const pageAux = searchByButton
        ? 0
        : Number((paginationMeta?.page ?? 0) > 0 ? paginationMeta?.page : 0);
      getUsersByCompany(companyId, pageAux + 1, 15, search)
        .then((response: IPaginationUserDTO) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].currentRole = getCurrentRole(
              response.data[i].roles
            );
          }

          setUsers(
            produce((draft) => {
              draft.push(...response.data);
            })
          );
          setPaginationMeta(response.meta);
        })
        .catch((error: IApiThrowsError) => {
          Logger.debug('error: ', error);
        })
        .finally(() => {
          setLoadingMembers(false);
        });
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (search.trim() !== lastSearch.current) {
      const execSearch = () => {
        lastSearch.current = search.trim();
        setUsers([]);
        searchUsers(true);
      };

      if (search.trim() === '') {
        execSearch();
      } else {
        timer = setTimeout(execSearch, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [search]);

  useEffect(() => {
    const intCompanyId = parseInt(companyId);

    if (Number.isInteger(intCompanyId) && intCompanyId > 0) {
      getUsers(intCompanyId.toString(), 1, 15);
    } else {
      navigate(getRouteFrom(REDIRECT));
    }
  }, [companyId, navigate]);

  return (
    <MainContainer data-cy="page-container">
      <Header>
        <TitlePage data-cy="title-pageName">Users</TitlePage>
        <Can roles={AlmostRoles}>
          <ButtonContainer>
            <Button
              dataCy="button-addUser"
              onClick={() => {
                setDialogOpen(true);
              }}
              type="button"
              disabled={loadingInvite}
            >
              Add User
            </Button>
          </ButtonContainer>
        </Can>
      </Header>

      <MainContent data-cy="list-card">
        <SearchBar
          search={search}
          placeholder="Search user"
          onChange={(e) => {
            if (e.target.value.length <= 60) {
              setSearch(e.target.value);
            }
          }}
          onClearInput={() => {
            setSearch('');
          }}
          loading={loadingMembers}
        />

        {loadingMembers && users.length === 0 ? (
          <LoadingContainer data-cy="loading-users-container">
            <Loading dataCy="loading-users" />
          </LoadingContainer>
        ) : (
          <ContentPagination dataCy="content-users">
            <Table
              loading={loadingMembers}
              columns={headerColumns}
              dataLength={users.length}
              next={searchUsers}
              hasMore={users.length < (paginationMeta?.total ?? 0)}
              noItensMessage="No users found"
            >
              {users.map((member) => (
                <Fragment key={member.id}>
                  <TableImageCell
                    text={`${member.firstName} ${member.lastName}`}
                    subtitle={member.email}
                    dataCy={`${member.id}`}
                    type="icon"
                    src={member.accountFile?.path}
                  >
                    <UserIcon />
                  </TableImageCell>
                  {companyId === COMPANY_ID ? (
                    <TableCell
                      text={member.managerType ?? '-'}
                      textAlign="center"
                    />
                  ) : (
                    <TableCell text="" textAlign="center" />
                  )}
                  <Can data-cy={AlmostRoles} roles={AlmostRoles}>
                    {(currentRole?.level ?? 0) >=
                      (member.currentRole?.level ?? 0) &&
                    member.id !== user?.id ? (
                      <>
                        <ItemGridCenter data-cy={`item-grid-${member.id}`}>
                          <SelectData
                            data={rolesAux}
                            dataCy="button-select-role"
                            name="select-role"
                            onValueChange={(e) => {
                              handleRoleSelectChange(
                                e,
                                member.email,
                                member.firstName,
                                member.lastName,
                                member.managerType,
                                member.phone
                              );
                            }}
                            defaultValue={member.roles[0]}
                          />
                        </ItemGridCenter>
                        <DropdownButtonContainer
                          data-cy={`dropdown-container-${member.id}`}
                        >
                          <BaseDropdown
                            dataCy={`dropdown-menu-button`}
                            onOpenChange={(isOpen) => {
                              setIsDropdownOpen(isOpen);
                            }}
                          >
                            <DropdownItem
                              data-cy={`edit-user`}
                              onClick={() => {
                                handleOpenModalEdit(member);
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              data-cy={`delete-user`}
                              onClick={() => {
                                handleOpenModalDelete(member);
                              }}
                            >
                              Delete
                            </DropdownItem>
                          </BaseDropdown>
                        </DropdownButtonContainer>
                      </>
                    ) : (
                      <>
                        <TableCell
                          text={member.currentRole?.value ?? '-'}
                          textAlign="center"
                          data-cy={member.currentRole?.value}
                        />
                        <TableCell text="" textAlign="center" />
                      </>
                    )}
                  </Can>
                </Fragment>
              ))}
            </Table>
          </ContentPagination>
        )}
      </MainContent>

      <BaseModal
        onOpenChange={setDialogLoadingOpen}
        open={dialogLoadingOpen}
        dataCy="spinner-loading-content-modal"
        hiddenCloseButton={true}
      >
        <Center>
          <Loading src={LoadingSpinnerAnimated} />
        </Center>
      </BaseModal>

      <DialogModal
        dataCy="delete-teammate-dialog-modal"
        acceptText="Confirm"
        declineText="Cancel"
        open={!isDropdownOpen && isModalDeleteOpen}
        loading={loadingDelete}
        mainText={`Are you sure you want to delete ${
          userToDelete?.firstName ?? 'this user'
        } ${userToDelete?.lastName ?? ''}?`}
        onAccept={handleAcceptDelete}
        onDecline={handleDeclineDelete}
        onOpenChange={handleDeclineDelete}
      />

      <ModalAddUsers
        onAccept={inviteMemberSubmit}
        onDecline={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
        loading={loadingInvite}
        success={successRequest}
      />

      <ModalUpdateUsers
        onAccept={handlerEditSubmit}
        onDecline={() => {
          setDialogEditOpen(false);
        }}
        onProfileChange={handleProfileChange}
        open={!isDropdownOpen && dialogEditOpen && emailEdit !== ''}
        loading={loadingEdit}
        id={idMemberToEdit}
        email={emailEdit}
        firstName={firstNameEdit}
        lastName={lastNameEdit}
        managerType={managerTypeEdit}
        phone={phoneEdit}
        currentRole={roleEdit}
        profileSrc={profileImage}
      />
    </MainContainer>
  );
};
