import { useCallback, useEffect, useRef, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';

import {
  DefaultDescription,
  FormBase,
  FormModal,
  Logger,
  Roles,
  SelectData,
  UploadImageModal,
  useToast,
} from '@gbs-monorepo-packages/common';
import { zodResolver } from '@hookform/resolvers/zod';

import { ManagerTypes } from '../../../../constants/ManagerTypes';
import {
  type UsersUpdateSchema,
  userUpdateSchema,
} from '../../../../formSchemas/userSchema';
import { deleteMemberProfile } from '../../../../services/users';

interface IUpdateTeammatesProps {
  onAccept: (data: UsersUpdateSchema) => void;
  onDecline: () => void;
  onProfileChange: (value: string, userId: number) => void;
  open: boolean;
  loading: boolean;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  managerType: string;
  phone: string;
  currentRole: string;
  profileSrc: string;
}

export const ModalUpdateUser = ({
  onAccept,
  onDecline,
  onProfileChange,
  open,
  loading = false,
  id,
  email,
  firstName,
  lastName,
  managerType,
  phone,
  currentRole,
  profileSrc,
}: IUpdateTeammatesProps): JSX.Element => {
  const isSelectOpen = useRef(new Set());
  const selectDataManagerType = useRef(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loadingDeleteImage, setLoadingDeleteImage] = useState<boolean>(false);
  const { addToast } = useToast();

  const updateUser = useForm<UsersUpdateSchema>({
    resolver: zodResolver(userUpdateSchema),
  });

  const {
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = updateUser;

  const values = watch();

  const resetForm = useCallback(() => {
    setValue('email', email);
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('managerType', managerType);
    setValue('phone', phone);
    setValue('currentRole', currentRole);
    setImageFile(null);
  }, [email, firstName, lastName, managerType, phone, currentRole, setValue]);

  const handleManagerTypeInviteSelectChange = (value: string) => {
    setValue('managerType', value);
  };

  const handleDeclineAddTeammates = useCallback(() => {
    if (!isSelectOpen.current.size) {
      resetForm();
      onDecline?.();
    }
  }, [onDecline, resetForm]);

  useEffect(() => {
    if (!loading) {
      resetForm();
    }
  }, [loading]);

  const handleDeleteImage = useCallback(
    (id: number) => {
      setLoadingDeleteImage(true);

      deleteMemberProfile(id)
        .then(() => {
          setLoadingDeleteImage(false);
          setImageFile(null);
          onProfileChange('', id);
          addToast({
            title: 'Image deleted',
            description: `Profile image successfully deleted.`,
            styleType: 'success',
          });
        })
        .catch((err) => {
          setLoadingDeleteImage(false);
          addToast({
            title: 'Error on delete image',
            description: DefaultDescription,
            styleType: 'error',
          });
          Logger.debug('err on delete image: ', err);
        });
    },
    [addToast]
  );

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleOpenChange = useCallback((isOpen: boolean, key: string) => {
    if (isOpen) {
      isSelectOpen.current.add(key);
    } else {
      isSelectOpen.current.delete(key);
    }
  }, []);

  const onSubmit: SubmitHandler<UsersUpdateSchema> = (
    data: UsersUpdateSchema
  ) => {
    const userAux = {
      ...data,
    };

    if (imageFile) {
      userAux.profile = imageFile;
    }

    onAccept?.(userAux);
  };

  return !open ? (
    <></>
  ) : (
    <FormBase.Provider {...updateUser}>
      <FormModal
        acceptText="Update User"
        declineText="Cancel"
        open={open}
        dataCy="add-userForm"
        mainText="Edit User"
        onOpenChange={handleDeclineAddTeammates}
        onDecline={handleDeclineAddTeammates}
        onAccept={handleSubmit(onSubmit)}
        loading={loading || loadingDeleteImage}
        formId="add-clientForm"
      >
        <FormBase.Content>
          <UploadImageModal
            label="Profile Image"
            imageSrc={profileSrc}
            onSelectImage={(file) => {
              setImageFile(file);
            }}
            onRemoveImagePreview={() => {
              setImageFile(null);
            }}
            onRemoveImage={() => {
              handleDeleteImage(id);
            }}
            loadingDeleteImage={loadingDeleteImage}
          />
          <FormBase.InputContent
            filled={!!values?.firstName}
            inputRef="firstNameEdit"
            label="First Name"
            errorMessage={errors.firstName?.message}
            dataCy="label-First Name"
          >
            <FormBase.InputText
              dataCy="firstNameEdit-input"
              id="firstNameEdit"
              name="firstName"
              type="text"
              autoComplete="off"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.lastName}
            inputRef="lastNameEdit"
            label="Last Name"
            errorMessage={errors.lastName?.message}
            dataCy="label-Last Name"
          >
            <FormBase.InputText
              dataCy="lastNameEdit-input"
              id="lastNameEdit"
              name="lastName"
              type="text"
              maxLength={30}
            />
          </FormBase.InputContent>

          <FormBase.InputContent
            filled={!!values?.phone}
            inputRef="phoneEdit"
            label="Phone"
            errorMessage={errors.phone?.message}
            dataCy="phoneEdit"
          >
            <FormBase.InputText
              dataCy="phoneEdit-input"
              id="phoneEdit"
              name="phone"
              type="text"
              maxLength={15}
            />
          </FormBase.InputContent>

          {currentRole === Roles.MANAGER && (
            <FormBase.InputContent
              filled={!!values.managerType}
              inputRef="select-managerType"
              label="Manager Type"
              errorMessage={errors.managerType?.message}
              dataCy="selectManagerType"
              value={
                values.managerType && values.managerType?.length > 0
                  ? 'mangerType'
                  : undefined
              }
              isSelectData
            >
              <SelectData
                data={ManagerTypes}
                value={
                  values.managerType !== '' ? values.managerType : undefined
                }
                dataCy="select-managerType-field"
                onValueChange={handleManagerTypeInviteSelectChange}
                zIndex={2}
                ref={selectDataManagerType}
                onOpenChange={(isOpen) => {
                  handleOpenChange(isOpen, 'managerType');
                }}
                placeholder="Manager Type"
              />
            </FormBase.InputContent>
          )}
        </FormBase.Content>
      </FormModal>
    </FormBase.Provider>
  );
};
